import { createContext, useContext } from 'react';

type CurrentUser = {
  id: string;
  name: string;
  email: string;
  admin: boolean;
  // Add other user properties as needed
};

const CurrentUserContext = createContext<CurrentUser | null>(null);

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  if (context === undefined) {
    throw new Error('useCurrentUser must be used within a CurrentUserProvider');
  }
  return context;
};

export const CurrentUserProvider = CurrentUserContext.Provider;
